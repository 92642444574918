<template>
  <div style="position: relative">
    <div style="position: absolute; top: 6vh; left: 20vw; width: 50%">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="流媒体平台" prop="brand">
          <el-select v-model="form.brand" placeholder="请选择类型">
            <el-option
                v-for="item in cameraOwn"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.brand==4" label="请求地址端口：" prop="host">
          <el-input type="text" v-model="form.host" placeholder="ip:port"></el-input>
        </el-form-item>
        <el-form-item label="appkey:" prop="appkey">
          <el-input type="text" v-model="form.appkey" placeholder="请输入appkey"></el-input>
        </el-form-item>
        <el-form-item label="secret:">
          <el-input type="text" v-model="form.secret" placeholder="请输入secret"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        brand: "1",
        host: "",
        appkey: "",
        secret: ""
      },
      cameraOwn: [
        {
          id: "1",
          name: "萤石开放平台",
        },
        {
          id: "2",
          name: "乐橙云",
        },
        {
          id: "4",
          name: "海康安防平台",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //请求列表数据
    getList() {
      this.$get("/cameraToken/page").then((res) => {
        if (res.data.state == "success" && res.data.datas) {
          this.form = res.data.datas[0];
        }
      });
    },
    onSubmit() {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$postJSON("/cameraToken/save", {
          ...this.form,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("添加成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
  },
};
</script>

